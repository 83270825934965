import React, { useContext } from "react";

import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  ListItem,
  List,
  Link,
  IconButton,
} from "@material-ui/core";

import { useHistory, Link as RouterLink } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { FaFacebookF, FaTelegramPlane } from "react-icons/fa";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    padding: "50px 0px 0",
    zIndex: "2",
    overflow: " hidden",
    background: "rgba(255, 255, 255, 0.02)",
    "& .footerContentBox": {
      maxWidth: "340px",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
    "& .divider": {
      paddingTop: "17px",
      borderBottom: "1px solid #D0D0D0",
    },
    "& .copyright": {
      "& p": {
        fontWeight: "300",
        color: "#000",
        FontFamily: "Roboto",
        padding: "34px 0px",
      },
    },

    "& ul": {
      paddingLeft: "0",
      "& li": {
        padding: "0",
        alignItems: "center",
        color: theme.palette.text.gray,
        fontSize: "14px",
        fontWeight: "300",
        display: "block",
        "& svg": {
          marginRight: "10px",
          color: "#fe2efe",
          fontSize: "15px",
        },
      },
    },
    "& svg": {
      color: "rgba(255 255 255 / 30%)",
      fontSize: "15px",
    },
    "& p": {
      color: theme.palette.text.gray,
    },
    "& h6": {
      color: "#1D1D1D",
      marginBottom: "17px",
      FontFamily: "Lato",
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },
    },
    "& .mails": {
      gap: "17px",
    },
    "& .socialMedia": {
      "& img": {
        margin: "15px 15px 0 0",
        cursor: "pointer",
      },
    },

    "& a": {
      display: "flex",
      fontSize: "14px",
      alignItems: "center",
      fontWeight: "400",
      paddingLeft: "0px",
      paddingRight: "0px",
      textDecoration: "none",
      color: theme.palette.text.primary,
      padding: "3px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "11px",
      },
      "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "none",
        "& svg": {
          color: "red",
          fontSize: "15px",
        },
      },
    },
    "& .borderBox": {
      position: "absolute",
      left: "153px",
      top: "12px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  iconbtn: {
    "& .MuiIconButton-root": {
      border: "0.5px solid #78819F",
      marginRight: "8px",
      marginBottom: "8px",
      borderRadious: "10px",
      borderRadius: "7px",
      width: "30px",
      height: "30px",
      padding: "0px",
      "& svg": {
        color: "#262626",
        fontSize: "18px",
        "& :hover": {
          "& svg": {
            color: "#EC1F24",
            fontSize: "18px",
          },
        },
      },
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();

  const mailData = [
    {
      image: <img src="images/hovrMail.svg" alt="hovr" />,
      email: "support@hovr.com",
    },
    {
      image: <img src="images/hovrMail.svg" alt="hovr" />,
      email: "info@hovr.com",
    },
    {
      image: <img src="images/hovrMail.svg" alt="hovr" />,
      email: "support@hovr.com",
    },
    {
      image: <img src="images/hovrMail.svg" alt="hovr" />,
      email: "info@hovr.com",
    },
  ];
  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3} md={3}>
              <Typography variant="h6" color="primary">
                About Us
              </Typography>
              <List>
                <ListItem to="/about" component={RouterLink}>
                  About
                </ListItem>
                <ListItem to="/about" component={RouterLink}>
                  Terms & Conditions
                </ListItem>
                <ListItem to="/about" component={RouterLink}>
                  Privacy Policy
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={6} sm={3} md={3}>
              <Typography variant="h6" color="primary">
                Products
              </Typography>
              <List>
                <ListItem to="/" component={RouterLink}>
                  Nft marketplace
                </ListItem>

                <ListItem to="/about" component={RouterLink}>
                  Game
                </ListItem>
                <ListItem to="/about" component={RouterLink}>
                  Dex
                </ListItem>
                <ListItem to="/about" component={RouterLink}>
                  Staking
                </ListItem>
                <ListItem to="/about" component={RouterLink}>
                  Qiedoodle
                </ListItem>
                <ListItem to="/about" component={RouterLink}>
                  Wallet
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <Typography variant="h6" color="primary">
                Developers
              </Typography>
              <List>
                <ListItem to="/" component={RouterLink}>
                  Support Center
                </ListItem>
                <ListItem to="/about" component={RouterLink}>
                  API Documentation
                </ListItem>

                <ListItem to="/about" component={RouterLink}>
                  API Key
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <Typography variant="h6" color="primary">
                Contact Us
              </Typography>
              <List>
                <ListItem>
                  {mailData.map((mail, i) => (
                    <Box className="displayStart mails" key={i}>
                      {mail.image}
                      <Link href="mailto:support@gmail.com">{mail.email}</Link>
                    </Box>
                  ))}
                </ListItem>
              </List>
              <Box className="socialMedia">
                <img src="images/fb.svg" alt="fb" />
                <img src="images/hovrTelegram.svg" alt="Telegram" />
                <img src="images/instagram.svg" alt="instagram" />
                <img src="images/hovrTwitter.svg" alt="Twitter" />
                <img src="images/hovrM.svg" alt="M" />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box className="divider" fullWidth></Box>
        <Container>
          <Box className="displayCenter copyright">
            <Typography variant="body1">© 2021 All Rights Reserved</Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
