import React, { useEffect, useState, useContext } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaMoneyBillWave, FaSquarespace } from "react-icons/fa";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";
import NavItem from "./NavItem";
import {
  BsCreditCardFill,
  BsInfoCircle,
  BsQuestionCircle,
} from "react-icons/bs";
import { AiFillDashboard, AiFillHome, AiOutlineLogout } from "react-icons/ai";
import { AuthContext } from "src/context/Auth";
import { IoWallet } from "react-icons/io5";
import { MdDashboard } from "react-icons/md";
import { BiTransfer } from "react-icons/bi";
import { TfiMoney } from "react-icons/tfi";
import { TbReceipt } from "react-icons/tb";
import { FaWallet } from "react-icons/fa6";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { RiBillLine } from "react-icons/ri";
import { HiViewGrid } from "react-icons/hi";
import { LuWallet } from "react-icons/lu";
import { RxDividerVertical } from "react-icons/rx";

const sections = [
  {
    items: [
      {
        title: "Home",
        modules: "",
        icon: AiFillHome,
        href: "/dashboard",
      },
      {
        title: "Payments",
        modules: "dashboard",
        icon: BsCreditCardFill,
        href: "/payment",
      },
      {
        title: "Balances",
        modules: "Balances",
        icon: FaMoneyBillWave,
        href: "/balance",
      },
      {
        title: "Customers",
        modules: "Wallet",
        icon: TfiHeadphoneAlt,
        //  img: <img src="images/dash1.png"/>,
        href: "/customer",
      },
      {
        title: "Billing",
        modules: "Billing Plans",
        icon:TbReceipt,
        // icon: ReceiptIcon,
        href: "/billing",
      },
      {
        title: "Wallet ",
        modules: "dashboard",
        icon: LuWallet,
        items: [
          {
            icon: RxDividerVertical,
            title: " Crypto",
            href: "/crypto-dashboard",
          },
          {
            icon: RxDividerVertical,
            title: "Fiat",
            href: "/fiat-wallet",
          },
        ],
      },
      {
        title: "More +",
        modules: "dashboard",
        icon: HiViewGrid,
        href: "/p2p-exchange",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    // width: 256,
    width: 358,
    background: "#fff",
  },
  desktopDrawer: {
    top: "76px",
    // width:"250px",
    maxWidth: "358px",
    width: "100%",
    height: "calc(100% - 115px)",
    // margin: "5px 10px 10px 15px",
    background: "#fff",
    // background: "#262626",
    // background: "#fff",

    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    // borderRadius: "10px",
    marginTop: "21px",
    // marginLeft: "13px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "19px",
    left: "17px",
    background: "transparent",
    fontWeight: "400",
    fontSize: "13px",
    color: "#EC1F24",
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const location = useLocation();
  let permission = auth.userData.permissions;
  let connectedExchange = auth.userData?.connectedExchange?.length;
  const history = useHistory();
  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]); // eslint-disable-line
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox">
            {sections.map((section, i) => {
              // ****************************************

              let item;
              let item1 = section.items.map((data, i) => {
                // eslint-disable-next-line
                if (section?.items[i]?.modules == "") {
                  return section.items[i];
                }
                // if (
                //   section?.items[i]?.modules == "dashboard" && // eslint-disable-line
                //   permission?.dashboard
                // ) {
                //   return section.items[i];
                // } else if (
                //   section?.items[i]?.modules == "exchange" && // eslint-disable-line
                //   permission?.exchange
                // ) {
                //   // eslint-disable-next-line
                //   if (connectedExchange == 0) {
                //     return section.items[i];
                //   } else {
                //     return {
                //       title: "Exchanges",
                //       modules: "exchange",
                //       icon: FaSquarespace,
                //       // img: <img src="images/dash1.png"/>,
                //       href: "/exchange",
                //     };
                //   }
                // } else if (
                //   section?.items[i]?.modules == "mywallet" && // eslint-disable-line
                //   permission?.mywallet
                // ) {
                //   return section.items[i];
                // } else if (
                //   section?.items[i]?.modules == "subscription" && // eslint-disable-line
                //   permission?.subscription
                // ) {
                //   return section.items[i];
                // } else if (
                //   section?.items[i]?.modules == "staticContentManagement" && // eslint-disable-line
                //   permission?.staticContentManagement
                // ) {
                //   return section.items[i];
                // } else if (
                //   section?.items[i]?.modules == "arbitrage" && // eslint-disable-line
                //   permission?.arbitrage
                // ) {
                //   return section.items[i];
                // } else if (
                //   section?.items[i]?.modules == "subscription" && // eslint-disable-line
                //   permission?.subscription
                // ) {
                //   return section.items[i];
                // }
                else {
                  return false;
                }
              });
              for (let k = 1; k < item1.length + 1; k++) {
                if (!item1[k]) {
                  item1.splice(k, 1);
                }
              }
              for (let l = 1; l < item1.length + 1; l++) {
                if (!item1[l]) {
                  item1.splice(l, 1);
                }
              }
              for (let m = 1; m < item1.length + 1; m++) {
                if (!item1[m]) {
                  item1.splice(m, 1);
                }
              }
              // eslint-disable-next-line
              // if (auth.userData.userType == "SUBADMIN") {
              //   item = item1; // eslint-disable-line
              // } else {
              //   item = section.items; // eslint-disable-line
              // }
              item = section.items; // eslint-disable-line
              // ****************************************
              return (
                <List
                  key={`menu${i}`}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    img: section.img,
                    items: item,
                    pathname: location.pathname,
                  })}
                </List>
              );
            })}
          </Box>
        </Box>

        {/* <Button
          className={classes.logoutButton}
          style={{ bottom: "110px", color: "#FFFFFF" }}
          onClick={()=>{history.push("/about")}}
        >
          <BsInfoCircle
            style={{
              marginRight: "16px",
              fontSize: "20px",
            }}
          />
          &nbsp; About
        </Button>

        <Button
          className={classes.logoutButton}
          style={{ bottom: "64px", color: "#FFFFFF" }}
          onClick={()=>{history.push("/help")}}
        >
          <BsQuestionCircle
            style={{
              marginRight: "16px",
              fontSize: "20px",
            }}
          />
          &nbsp; Help & Complaint
        </Button>
        <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          <AiOutlineLogout
            style={{ marginRight: "16px", color: "#EC1F24", fontSize: "25px" }}
          />
          &nbsp; Logout
        </Button> */}

        {isLogout && (
          <Dialog
            maxWidth="xs"
            fullWidth
            className={classes.dailogOpen}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <AiOutlineLogout
                  style={{
                    marginRight: "16px",
                    color: "#EC1F24",
                    fontSize: "65px",
                  }}
                />
                <Typography
                  variant="h5"
                  style={{
                    color: "#222222",
                    fontFamily: "poppins",
                    fontWeight: "400",
                    maxWidth: "275px",
                  }}
                >
                  Are you sure, you want to logout?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2} mb={2}>
                <Button
                  className="modelbtn"
                  color="secondary"
                  variant="contained"
                  style={{ padding: "8px 21px" }}
                  onClick={() => setIsLogout(false)}
                >
                  No
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="modelbtnyes"
                  style={{
                    padding: "8px 21px",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    window.localStorage.removeItem("token");
                    auth.userLogIn(false, null);
                    history.push("/");
                  }}
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
