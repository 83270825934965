import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { Box } from "@material-ui/core";
// import SettingsContext from "src/context/";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#eef4f8",
    // overflow: "hidden",
    position: "relative",
    height: "100vh",
    backgroundImage: "url(images/DashboardBack.png)",
  },

  wrapper1: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#eef4f8",
    // paddingTop: "70px",
    minHeight: "calc(100vh - 75px)",
    [theme.breakpoints.up("lg")]: {
      // paddingLeft: 256,
      paddingTop: "70px",
      paddingLeft: 358,
    },
    [theme.breakpoints.down("lg")]:{
      paddingTop: "64px",
    },
    [theme.breakpoints.down("sm")]:{
      paddingTop: "63px",
    },
  
  },

  wrapper2: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#eef4f8",
    paddingTop: 70,
    minHeight: "calc(100vh - 75px)",
    [theme.breakpoints.up("lg")]: {
      // paddingLeft: 256,
      paddingLeft: 0,
    },
    [theme.breakpoints.down("lg")]:{
      paddingTop: "64px",
    },
    [theme.breakpoints.down("sm")]:{
      paddingTop: "63px",
    },
  
  },

  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    padding: "28px 25px 25px ",
    backgroundImage: "url(images/LandingBack.png)",
    [theme.breakpoints.down("md")]: {
      padding: "25px 10px 10px ",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 10px ",
    },
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const allowedRoutes = [
    "/invoice",
    "/add-owner",
    "/stepper",
    "/payment-link",
    "/subsribtions/create-template",
    "/faqs",
    "/payment-screen",
  ];
  const isSpecialRoute = allowedRoutes.includes(window.location.pathname);

  return (
    <div className={classes.root}>
      {isSpecialRoute ? (
        <>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
          <div className={classes.wrapper2}>
            <div className={classes.contentContainer}>
              <div className={classes.content} id="main-scroll">
                {children}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />

          <div className={classes.wrapper1}>
            <div className={classes.contentContainer}>
              <div className={classes.content} id="main-scroll">
                {children}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
